import * as React from 'react';
import {Link, Box, IconButton,FormGroup, FormControlLabel, Checkbox, Card, CardContent, Stack, Divider, Grid, TextField, Button, InputLabel, MenuItem, FormControl, Select, Typography, ButtonGroup, LinearProgress} from '@mui/material';
import { ethers } from 'ethers'
//import BAYC from './artifacts/contracts/FakeBAYC.sol/BAYC.json'
import Option from './artifacts/contracts/ERC721Option.sol/ERC721Option.json'
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useState, useEffect } from 'react';

import SearchableContractInput from './contractSelectDropdown.js';

import { SelectNFT } from './OrderViewSelectNFT.js';

import {ZtrikeAddress,  NFTAddress, MarketplaceAddress} from './ethContracts';

import ModalCreateOrder from './ModalCreateOrder';
import {matchOrders, ethCreateOfferInitializeWallet, createOrder, acceptOrder, getOrder, cancelOrder} from './ethCreateOffer.js';
import { useConnectWallet } from '@web3-onboard/react'

import { getOrders, getBlockGas, supabase,getUniqueUnderlyingFromOrders } from './supaBaseFuncs.js';

import { convertBlocksToTime } from './Etherfuncs.js';

// Add this import at the top of the file
import VisibilityIcon from '@mui/icons-material/Visibility';

const INFURA_URL = process.env.REACT_APP_INFURA_URL;

export function OrdersView(props) {
    
    const theme = props.theme;
    const mode = props.mode;
    const toggleTabBar = props.toggleTabBar;
    const blockMapping = props.blockMapping;
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()

    React.useEffect(() => {
      if (wallet) {
        ethCreateOfferInitializeWallet(wallet);
      }
    }, [wallet]);

      const switchPC = props.switchPC;
      const userAccount = props.userAccount;

      const [MyOrders, setMyOrders] = useState([]);
      const [uniqueContracts, setUniqueContracts] = useState(['Nothing']);
      const [curr, setCurr] = React.useState(0);
      const [currGas, setCurrGas] = React.useState(0);
      const [filterOn, setFilterOn] = React.useState(false);
    
      
      const [filterContract, setFilterContract] = useState('');
      const [filterCreator, setFilterCreator] = useState('');
      const [showExpired, setShowExpired] = useState(false);
      const [showCancelled, setShowCancelled] = useState(false);
      const [filteredOrders, setFilteredOrders] = useState([]);

      // Select NFT modal
      const [modalVisisble, setModalVisible] =  useState(false);
      const [modalSelectedNFT, setModalSelectedNFT] = useState({0: ''});

      const [open, setOpen] = React.useState(false);

      const [OrderModalVisible, setOrderModalVisible] = useState(false); 
    // Add this new state near your other useState declarations
    const [showOnlyMatching, setShowOnlyMatching] = useState('');
    // Add this state to store all best matches
    const [bestMatches, setBestMatches] = useState({});

    // Add these new state variables
    const [cancelStatus, setCancelStatus] = useState({});
    const [cancelLoading, setCancelLoading] = useState({});

    // Add this near your other state declarations
    const [urlFilterOrderId, setUrlFilterOrderId] = useState('');

    // Add these state variables near your other useState declarations
    const [openModalId, setOpenModalId] = useState(null);
    const handleClose = () => setOpenModalId(null);

    // Add this useEffect to handle URL hash changes
    useEffect(() => {
        const handleHashChange = () => {
            const hashParts = window.location.hash.toLowerCase().split('#');
            // Remove empty string from split
            const filteredParts = hashParts.filter(part => part);
            
            if (filteredParts[0] === 'orders' && filteredParts[1] !== undefined) {
                // Convert to integer and validate
                const orderId = parseInt(filteredParts[1], 10);
                if (!isNaN(orderId) || orderId === 0) {
                    setUrlFilterOrderId(orderId);
                    setShowOnlyMatching(orderId.toString());
                } else {
                    setUrlFilterOrderId('');
                    setShowOnlyMatching('');
                }
            } else {
                setUrlFilterOrderId('');
                setShowOnlyMatching('');
            }
        };

        handleHashChange();
        window.addEventListener('hashchange', handleHashChange);
        return () => window.removeEventListener('hashchange', handleHashChange);
    }, []);

    // Add this function to handle cancel order
    const handleCancelOrder = async (orderId, isOffer) => {
        try {
            setCancelLoading(prev => ({ ...prev, [orderId]: true }));
            setCancelStatus(prev => ({ ...prev, [orderId]: 'Cancelling order...' }));
            
            const result = await cancelOrder(orderId, isOffer);
            
            setCancelStatus(prev => ({ 
                ...prev, 
                [orderId]: result.success ? 'Order cancelled successfully' : `Failed: ${result.message}`
            }));
            
            // Clear status after 5 seconds if successful
            if (result.success) {
                setTimeout(() => {
                    setCancelStatus(prev => {
                        const newStatus = { ...prev };
                        delete newStatus[orderId];
                        return newStatus;
                    });
                }, 5000);
            }
        } catch (error) {
            setCancelStatus(prev => ({ 
                ...prev, 
                [orderId]: `Error: ${error.message}` 
            }));
        } finally {
            setCancelLoading(prev => {
                const newLoading = { ...prev };
                delete newLoading[orderId];
                return newLoading;
            });
        }
    };

    // Add this effect to calculate all best matches when orders change
    useEffect(() => {
        const calculateBestMatches = () => {
            const matches = {};
            MyOrders.forEach(order => {
                matches[order._orderId] = getBestMatch(
                    order.optionId,
                    order.isOffer,
                    order._orderId
                );
            });
            setBestMatches(matches);
        };

        calculateBestMatches();
    }, [MyOrders]); // Recalculate when orders change


      const fetchBlock = async () => {
        let [bb, fGas] = await getBlockGas();
        console.log("GETTING BLOCKNO...", bb, fGas);
        setCurrGas(fGas);
        setCurr(bb);
    }
    

    function toggleFilter(){
        setFilterOn(!filterOn);
    }


// Add this new function to handle order matching
async function handleMatchOrders(optionId, orderId, isOffer) {
    console.log("Matching start", optionId, orderId, isOffer);
    const matchingOrderId = findBestPrice(optionId, isOffer, orderId, false);
    console.log("Matching moi", matchingOrderId);
    if (matchingOrderId !== null && matchingOrderId !== undefined && matchingOrderId !== '') {
        const order = MyOrders.find(o => o._orderId === orderId);
        const matchingOrder = MyOrders.find(o => o._orderId === matchingOrderId);
        
        // Calculate price difference if accepting at offer price
        const priceDifference = isOffer ? 
            0 : // If we're the offer creator accepting a bid
            Number(matchingOrder.price) - Number(order.price); // If we're the bid creator accepting an offer
            console.log("Matching pd", priceDifference);
        try {
            console.log("Matching: evm called");
            await matchOrders(
                isOffer ? matchingOrderId : orderId, // bidId
                isOffer ? orderId : matchingOrderId, // offerId
                { value: priceDifference }
            );
            
        } catch (error) {
            console.error('Error matching orders:', error);
        }
    }
}
    
    useEffect(() => {
        console.log("supabase: SUBSCRIBING TO CHANNEL orders");
        const channels = supabase.channel('orders-channel')
        .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'orders' },
        (payload) => {
            console.log('supabase: Change received for orders!', payload);
            if (payload.eventType === 'INSERT') {
                console.log('supabase: New order inserted:', payload.new);
            } else if (payload.eventType === 'UPDATE') {
                console.log('supabase: Order updated:', payload.new);
            } else if (payload.eventType === 'DELETE') {
                console.log('supabase: Order deleted:', payload.old);
            }
            GetOrders();
        }
        )
        .subscribe((status) => {
            console.log('supabase: Subscription status for orders:', status);
        })
      
        return () => {
            console.log('supabase: Unsubscribing from orders channel');
            channels.unsubscribe();
        };
    }, []);
    
    useEffect(() => {
        console.log("supabase: SUBSCRIBING TO CHANNEL (block)");
        const channels = supabase.channel('ordersView-block-channel')
        .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'blockInfo' },
        (payload) => {
            console.log('supabase: blockInfo Change received!', payload);
            fetchBlock();
        }
        )
        .subscribe((status) => {
            console.log('supabase: Subscription status for blockInfo:', status);
        })
      
        return () => {
            console.log('supabase: Unsubscribing from blockInfo channel');
            channels.unsubscribe();
        };
    }, []);

      useEffect(() => {
        console.log("fetching blocks at startup");
            fetchBlock();

      }, []);

      
    async function getOptionParameters(optionId) {
        if (typeof window.ethereum !== 'undefined' | true) {
            const provider = new ethers.InfuraProvider("sepolia", INFURA_URL);
            const contract = new ethers.Contract(ZtrikeAddress, Option.abi, provider);   
            const opt = await contract.Options(optionId);
            //console.log("", opt);   
            const OptionParameters = {
                optionId:Number(opt[0]), 
                isCall:opt[1],
                strike:ethers.formatEther(opt[2]),
                expiry:Number(opt[3]),
                optWriter:opt[4],
                contractAddress:opt[5],
                underlyingId:Number(opt[6]),
            }; 
            //console.log(OptionParameters);
            return OptionParameters;
        }
    }


    async function addNewToMyOrders(g){

        const opt = await getOptionParameters(g.optiondId);
        const gg = {'_orderId':g._orderId,
            'optionId':g.optionId,
            'price':g.price,
            'isBid':g.isBid,
            'validUntil':g.validUntil,
            'orderCreator':g.orderCreator,
            'underlyingContract': opt.contractAddress,
            'underlyingId':opt.underlyingId,
            'cOptionId': opt.optionId,
            'strike':opt.strike,
            'expiry':opt.expiry,
            'isCall': opt.isCall,
        }

        //let newOrders = MyOrders;
        //newOrders.push(gg);
        setMyOrders(prevState => ([
            ...prevState,
            gg
        ]));
    }

// Modify your GetOrders function to return the fetched orders
async function GetOrders() {
    console.log("getting orders")
    const orders = await getOrders();
    setMyOrders(orders);

    // get unique orders
    console.log(orders);
    let unique = orders.map(item => item.underlyingContract)
    .filter((value, index, self) => self.indexOf(value) === index)
    console.log("unique", unique);
    setUniqueContracts(unique);

    return orders;
};

// Update your useEffect to use the returned orders
useEffect(() => {
    async function fetchOrders() {
        if (MyOrders.length === 0) {
            const fetchedOrders = await GetOrders();
            filterOrders(fetchedOrders);
        }
    }
    fetchOrders();
}, []); // run only once

// Add this helper function to get both price and orderId at once
function getBestMatch(optionId, isOffer, orderId) {
    const price = findBestPrice(optionId, isOffer, orderId, true);
    const matchingOrderId = price !== '' ? findBestPrice(optionId, isOffer, orderId, false) : '';
    return { price, matchingOrderId };
}

// Modify the filter function to use a cached match when filtering
// Update the filter function to use bestMatches
function filterOrders(orders) {
    if (orders.length > 0) {
        let f = orders.filter(order => {
            // Check URL filter first
            if (urlFilterOrderId !== '') {
                return Number(order._orderId) === urlFilterOrderId;
            }
            
            // Your existing filtering logic
            if (showOnlyMatching !== '') {
                const originalOrder = orders.find(o => o._orderId === showOnlyMatching);
                if (!originalOrder) return false;
                
                const matchingOrderId = bestMatches[showOnlyMatching]?.matchingOrderId;
                return order._orderId === showOnlyMatching || order._orderId === matchingOrderId;
            }

            // Original filtering logic
            const contractMatch = !filterContract || order.underlyingContract.toLowerCase() === filterContract.toLowerCase();
            const creatorMatch = !filterCreator || order.orderCreator.toLowerCase() === filterCreator.toLowerCase();
            const expiryMatch = showExpired || (Number(order.validUntil) - curr) >= 1;
            const cancelledMatch = showCancelled || !order.isCancelled;
            return contractMatch && creatorMatch && expiryMatch && cancelledMatch;
        });
        setFilteredOrders(f);
    }
}


function findBestPrice(optionId, isOffer, orderId, returnPrice = true) {
    console.log("findBestPrice", optionId, isOffer, orderId, returnPrice);
    // Filter out cancelled and expired orders
    const validOrders = MyOrders.filter(order => 
        !order.isCancelled && 
        order._orderId != orderId &&
        (Number(order.validUntil) - curr) >= 1
    );

    if (validOrders.length === 0) return '';

    if (!isOffer) { // If our order is a bid
        // Get the order details to match against
        const ourBid = MyOrders.find(order => order._orderId === orderId);
        if (!ourBid){
            console.log("ourBid not found", orderId);
            return '';
        };

        // Filter offers that match our bid criteria
        const matchingOffers = validOrders.filter(order => {
            if (!order.isOffer) return false;
            
            // For specific bids, only match the exact optionId
            if (!ourBid.isGeneral) {
                return order.optionId === ourBid.optionId;
            }
            
            // For general bids, match based on option parameters
            return order.underlyingContract === ourBid.underlyingContract &&
                   order.isCall === ourBid.isCall &&
                   Number(order.strike) === Number(ourBid.strike) &&
                   Number(order.expiry) === Number(ourBid.expiry);
        });

        if (matchingOffers.length === 0) return '';
        
        // Find lowest matching offer
        const lowestOffer = matchingOffers.reduce((min, offer) => 
            Number(offer.price) < Number(min.price) ? offer : min
        );
        
        return returnPrice ? lowestOffer.price : lowestOffer._orderId;

    } else { // If our order is an offer
        // Filter for matching bids (both specific and general)
        const matchingBids = validOrders.filter(order => {
            if (order.isOffer) return false;
            
            // For specific bids, match exact optionId
            if (!order.isGeneral) {
                return order.optionId === optionId;
            }
            
            // For general bids, check if our offer matches their criteria
            const offerDetails = MyOrders.find(o => o._orderId === orderId);
            return order.underlyingContract === offerDetails.underlyingContract &&
                   order.isCall === offerDetails.isCall &&
                   Number(order.strike) === Number(offerDetails.strike) &&
                   Number(order.expiry) === Number(offerDetails.expiry);
        });

        if (matchingBids.length === 0) return '';
        
        // Find highest matching bid
        const highestBid = matchingBids.reduce((max, bid) => 
            Number(bid.price) > Number(max.price) ? bid : max
        );
        
        return returnPrice ? highestBid.price : highestBid._orderId;
    }
}

// Update your first useEffect to use the filterOrders function
useEffect(() => {
    filterOrders(MyOrders);
}, [MyOrders, showExpired, showCancelled, filterContract, filterCreator, curr, showOnlyMatching]);
    
    useEffect(() => {
        console.log("Updated myOrders");
    }, [MyOrders]);

    // Modify the visibility icon click handler to update URL
    const handleVisibilityClick = (orderId) => {
        if (showOnlyMatching !== '') {
            setShowOnlyMatching('');
            window.history.replaceState(null, null, '#orders');
        } else {
            setShowOnlyMatching(orderId.toString());
            window.history.replaceState(null, null, `#orders#${orderId}`);
        }
    };

    // Add this function near your other handlers
    const clearUrlFilter = () => {
        setUrlFilterOrderId('');
        setShowOnlyMatching('');
        window.history.replaceState(null, null, '#orders');
    };

    return(
    <Card sx={{maxWidth:{xs:'100%', sm:'100%', lg:'100%'}, minWidth: {sm:'97%', sm:'90%', lg:'50%'}, paddingLeft:1, paddingRight:0}}>
      <CardContent sx={{paddingLeft:0, paddingRight:0,  marginLeft:1, marginRight:0}}>    
          
          <Grid container  spacing={{ xs: 2, sm:3, md: 4 }} justify='center' 
          alignItems="stretch" columns={{ xs: 2, sm: 4, md: 6 }}>              
              <Grid item xs={12} style={{ paddingTop: 10 }}>
                  <Typography variant="h6" align='left' style={{paddingBottom:0, paddingTop:10}}>
                      {urlFilterOrderId ? `Order #${urlFilterOrderId}` : `My orders (${filteredOrders.length})`}
                  </Typography>
                  
                  <Stack 
                      direction="row" 
                      sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          width: '100%',
                          paddingTop: 2,
                          paddingRight: 1
                      }}
                  >
                      <Box sx={{ width: 'auto', paddingBottom:1, marginTop:-6.5, paddingRight:1 }}>
                          <IconButton onClick={() => toggleFilter()}>
                              {filterOn ? <FilterListOffIcon/> : <FilterListIcon/>}
                          </IconButton>
                      </Box>

                      <Box sx={{ width: 'auto', paddingBottom:1, marginTop:-6, marginRight:1 }}>
                          <div style={{
                              margin:7,
                              marginTop:0,
                              justifyContent:'flex-end', 
                              width:'100%',
                              display: 'flex'
                          }}>
                              <Button variant="outlined" size="small" disabled={false} 
                                  onClick={() => setOrderModalVisible(true)} 
                                  color={props.switchPC == 'Call' ? 'primary': 'secondary'}
                                  sx={{border:1, borderColor:'primary.main', width:'100%'}}
                              >
                                  <Typography sx={{fontSize:12, color:'primary.main'}}>
                                      New order
                                  </Typography>
                              </Button>
                          </div>
                      </Box>
                  </Stack>

                  {/* Add Show All button below other elements when URL filter is active */}
                  {urlFilterOrderId && (
                      <Box sx={{ 
                          width: '100%', 
                          display: 'flex', 
                          justifyContent: 'flex-start',
                          mt: 1,
                          mb: 1
                      }}>
                          <Button 
                              size="small"
                              variant="outlined"
                              onClick={clearUrlFilter}
                              sx={{ 
                                  minWidth: '100px',
                                  fontSize: '0.75rem'
                              }}
                          >
                              Show all orders
                          </Button>
                      </Box>
                  )}

                  {/* Rest of your filter content */}
                  {filterOn && (
                      <>
                          <Divider textAlign="left" sx={{paddingBottom:2}}>Filter Orders</Divider>
                          <SearchableContractInput
                              contracts={uniqueContracts}
                              onChange={setFilterContract}
                              label="Underlying Contract"
                              sx={{paddingBottom:2, paddingRight:1}}
                          />
                          
                          <TextField 
                              id="outlined-basic" 
                              label="Order Creator" 
                              fullWidth
                              variant="outlined" 
                              size="small"
                              value={filterCreator}
                              onChange={(e) => setFilterCreator(e.target.value)}
                              sx={{paddingBottom:-1, paddingRight:1}}
                          />

                          <FormGroup>
                              <FormControlLabel 
                                  control={
                                      <Checkbox 
                                          checked={showExpired} 
                                          onChange={(e) => setShowExpired(e.target.checked)}
                                      />
                                  } 
                                  label="Show expired orders" 
                              />
                              <FormControlLabel 
                                  control={
                                      <Checkbox 
                                          checked={showCancelled} 
                                          onChange={(e) => setShowCancelled(e.target.checked)}
                                      />
                                  } 
                                  label="Show cancelled orders" 
                              />
                          </FormGroup>
                          <Divider sx={{paddingTop:2}}/>
                      </>
                  )}
              </Grid>

              <Grid container item xs={12} wrap='nowrap' style={{ paddingTop: 25, 
                                                maxHeight:'500px',
                                                overflowY:'hidden',
                                                overflowX: 'atuo',
                                                display: "flex",
                                                flexDirection:"row",
                                                maxWidth: '100%',
                                                scrollbarColor: '#282929 #6f7070',
                                                scrollbarWidth: 'thin',
                                                marginBottom: 0}}>
                {  filteredOrders.length == 0 ? 
                <Typography>
                    Loading...
                </Typography> 
                :

                
                filteredOrders.map( (e, index) => { 
                        return (
                            <Grid item xs={12} sm={10} md={6} style={{ padding: 3, marginBottom:10, minWidth:'190px'}} key={e._orderId} >
                                 <Card style={{backgroundImage: theme.custom.gradient1, 
                                    padding:2, height:'100%'}}>
                                    <Typography  variant='h5' align="left" color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                                        Order #{String(e._orderId)}
                                     </Typography>
                                    
                                    <Divider textAlign="left" color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>
                                    <Typography  variant='body2'  align="left" sx={{fontSize:18}} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                                        {!e.isOffer ? 'Bid to buy' : 'Offer to sell'} 
                                    </Typography>
                                    </Divider>
                                    
                                    
                                    <Typography  variant='body2' align="left" color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  Price: Ξ{String(ethers.formatEther(String(e.price)))} </Typography>
                                    <Typography gutterBottom variant='body2' align="left" color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  Valid until : {String(e.validUntil)} </Typography>
                                    <Typography gutterBottom variant='body2' align="left" color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  {String(Number(e.validUntil) - curr)} blocks left </Typography>
                                    <Typography gutterBottom variant='body2' align="left" fontSize={8} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  ({convertBlocksToTime(Number(e.validUntil) - curr)}) </Typography>
                                    
                                    <Typography gutterBottom variant='body2' align="left" color={(e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>
                                    Creator: 
                                    <Link 
                                        href={String(e.orderCreator).toLowerCase() === String(userAccount).toLowerCase() ? '#' : `https://etherscan.io/address/${e.orderCreator}`} // Adjust the link as needed
                                        color= {mode == "light" ? "primary.dark" : "primary.light"} // Use inherit color to match the text color
                                        underline="hover" // Optional: adds an underline on hover
                                    >
                                        {String(e.orderCreator).toLowerCase()  === String(userAccount).toLowerCase() ? ' You' : ` ${e.orderCreator.slice(0, 6)}...${e.orderCreator.slice(-4)}`}
                                    </Link>
                                </Typography>
                                    {e.isFilled ? 
                                        <Typography gutterBottom variant='body' align="left" color='primary'> Order Filled </Typography>
                                        : '' }

                                    {(e.isCancelled) ? 
                                        <Typography gutterBottom variant='body' align="left" color="secondary"> Order Cancelled </Typography>
                                        : '' }

                                    {(  ((Number(e.validUntil) - curr) < 1) & !e.isFilled & !e.isCancelled) ? 
                                        <Typography gutterBottom variant='body' align="left" color="secondary"> Order Expired </Typography>
                                        : '' }
                                    { (e.isFilled | e.isCancelled) ? '' : 
                                    <br/>}
                                    
                                    <Divider textAlign="left" color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}> 
                                    
                                    { e.isGeneral ?
                                    <Typography  variant='body' align="left" color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                                        <Typography  variant='body' align="left" style={{fontSize:10}} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                                            Any option matching:
                                        </Typography>
                                    </Typography>
                                    : 
                                    <Typography  variant='body' align="left" color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                                        <Link
                                            component="button"
                                            variant="body2"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                window.directLinkNavigation = true;
                                                setTimeout(() => {
                                                    window.location.hash = `options#${e.optionId}`;
                                                    toggleTabBar(1);
                                                }, 0);
                                            }}
                                            color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}
                                            underline="hover"
                                            sx={{ 
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    textDecoration: 'underline'
                                                }
                                            }}
                                        >
                                            {`Option #${String(e.optionId)}`}
                                        </Link>
                                    </Typography>

                                    }
                                    
                                    
                                    </Divider>

                                    <Typography  variant='body2' align="left" style={{fontSize:12}} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                                        Type: {e.isCall ? 'Call' : 'Put'} </Typography>
                                    
                                    <Typography  variant='body2' align="left" style={{fontSize:12}} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                                        Contract {String(e.underlyingContract).slice(0,6)} ...{String(e.underlyingContract).slice(String(e.underlyingContract).length - 4)} </Typography>
                                    
                                    { e.isGeneral ? 
                                        <Typography  variant='body2' align="left" style={{fontSize:12}} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>
                                        Any underlying ID
                                        </Typography>
                                    :
                                        <Typography  variant='body2' align="left" style={{fontSize:12}} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>
                                              Underlying ID #{String(e.underlyingId)} 
                                        </Typography>

                                    }
                                    
                                    <Typography  variant='body2' align="left" style={{fontSize:12}} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                                        Strike Ξ{String(e.strike)} </Typography>
                                    <Typography  variant='body2' align="left" style={{fontSize:12}} color={ (e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"}>  
                                        Expiry {String(e.expiry)} ({String(Number(e.expiry) - curr)} left, {convertBlocksToTime(Number(e.expiry) - curr)}) </Typography>
                                    <Divider/>
                                    
                                    
                                    
                                    
                                    
<Stack direction="row" alignItems="center" spacing={1}>
    <Typography 
        variant='body2' 
        align="left" 
        style={{fontSize:16}} 
        color={(e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1) ? "text.disabled" : "text.primary"} 
        sx={{fontSize:18}}
    >    
        {!e.isOffer ? 'Best Offer: Ξ' : 'Best Bid: Ξ'}
        {bestMatches[e._orderId]?.price 
            ? ethers.formatEther(String(bestMatches[e._orderId].price)) 
            : '-'
        }
    </Typography>
    {bestMatches[e._orderId]?.price && (
        <IconButton 
            size="small"
            onClick={() => handleVisibilityClick(e._orderId)}
            sx={{ 
                padding: 0,
                color: showOnlyMatching === e._orderId ? 'primary.main' : 'text.secondary'
            }}
        >
            <VisibilityIcon sx={{ fontSize: 16 }} />
        </IconButton>
    )}
</Stack>
                                   
                                {
                                    (String(e.orderCreator).toLowerCase() != String(userAccount).toLowerCase()) ? 
                                    (
                                     !e.isGeneral ?
                                            <Button variant="contained"  size="small"
                                            color={switchPC == 'Call' ? 'primary': 'secondary'}
                                            sx={{width:'100%', marginBottom:1, marginTop:'auto'}}
                                            onClick={() =>acceptOrder(e._orderId)}
                                            disabled = {e.isFilled 
                                                | e.isCancelled 
                                                | (Number(e.validUntil) - curr) < 1
                                                |  (String(e.orderCreator).toLowerCase() === String(userAccount).toLowerCase())
                                            }
                                            >
                                            Accept {e.isOffer ? 'offer' : 'bid'}
                                            </Button>

                                        :   

                                        <ButtonGroup sx={{width:'100%'}}>
                                            
                                            <>
                                            <Button variant="outlined"  size="small"
                                                color={switchPC == 'Call' ? 'primary': 'secondary'}
                                                sx={{width:'100%', marginBottom:1, marginTop:'auto', fontSize:10}}
                                                onClick={() => setOpen(true)}
                                                >
                                                    {
                                                        modalSelectedNFT[e._orderId] ?
                                                        `#${modalSelectedNFT[e._orderId]}` :
                                                        'Select'
                                                    }
                                            </Button>
                                            <SelectNFT
                                            setModalSelectedNFT = {setModalSelectedNFT}
                                            modalSelectedNFT = {modalSelectedNFT}
                                            userAccount = {userAccount}
                                            filterContract = {e.underlyingContract}
                                            filterIsCall = {e.isCall}
                                            filterStrike = {e.strike}
                                            filterExpiry = {e.expiry}
                                            orderId = {e._orderId}
                                            theme = {theme}
                                            mode = {mode}
                                            toggleTabBar = {toggleTabBar}
                                            setOpen={setOpen}
                                            open={open}
                                            />
                                            </>
                                            
                                            

                                            <Button variant="contained"  size="small"
                                            color={switchPC == 'Call' ? 'primary': 'secondary'}
                                            sx={{width:'100%', marginBottom:1, marginTop:'auto', fontSize:10}}
                                            onClick={() =>acceptOrder(e._orderId, modalSelectedNFT[e._orderId])}
                                            disabled = { !modalSelectedNFT[e._orderId] 
                                                || e.isFilled 
                                                || e.isCancelled 
                                                || (Number(e.validUntil) - curr) < 1
                                                ||  (String(e.orderCreator).toLowerCase() === String(userAccount).toLowerCase())
                                            }
                                                >
                                                Accept #{modalSelectedNFT[e._orderId]}
                                            </Button>
                                        </ButtonGroup>
                                    )
                                    : ''
                                }
                                     
                                    {
                                        (String(e.orderCreator).toLowerCase() != String(userAccount).toLowerCase()) ?
                                    <Button variant="outlined"  size="small"
                                                color={switchPC == 'Call' ? 'primary': 'secondary'}
                                                sx={{width:'100%'}}
                                                onClick={() => setOpenModalId(e._orderId)}
                                                disabled={e.isFilled}
                                        >
                                        {e.isOffer ? 'Place bid' : 'Place offer'}
                                    </Button>
                                    : ''
                                    }
                                    {
                                        (String(e.orderCreator).toLowerCase() == String(userAccount).toLowerCase()) ?   
                                    <Button variant="outlined"  size="small"
                                                color={switchPC == 'Call' ? 'primary': 'secondary'}
                                                sx={{width:'100%'}}
                                                onClick={() => handleMatchOrders(e.optionId, e._orderId, e.isOffer) //acceptOrder(findBestPrice(e.optionId, e.isOffer, false), e.optionId)
                                                    }
                                                disabled={!findBestPrice(e.optionId, e.isOffer, e._orderId) || 
                                                                        e.isFilled || 
                                                                        e.isCancelled || 
                                                                        (Number(e.validUntil) - curr) < 1} // if order is already filled (we already sold), we assume we dont want to accept another bid
                                        >
                                       {e.isOffer ? 'Accept highest bid' : 'Accept lowest offer'} 
                                    </Button>
                                    : ''
                                    }

                                    <Stack>
                                        <ButtonGroup variant="outlined"  size="small" fullWidth
                                                    color={switchPC == 'Call' ? 'primary': 'secondary'}
                                                    sx={{width:'100%', paddingTop:1}}
                                            >
                                            <Button 
                                            disabled = {e.isFilled | e.isCancelled | (Number(e.validUntil) - curr) < 1 | e.orderCreator.toLowerCase() != String(userAccount).toLowerCase()}
                                            > Edit 
                                            </Button>
                                            <Button 
                                            disabled = {e.isFilled | e.isCancelled | e.orderCreator.toLowerCase() != String(userAccount).toLowerCase() || cancelLoading[e._orderId]}
                                            onClick={() => handleCancelOrder(e._orderId, e.isOffer)}
                                            > Cancel </Button>
                                        </ButtonGroup>
                                    </Stack>
                                    {cancelLoading[e._orderId] && (
                                        <Box sx={{ width: '100%', mt: 1 }}>
                                            <LinearProgress color={switchPC == 'Call' ? 'primary': 'secondary'} />
                                        </Box>
                                    )}
                                    {cancelStatus[e._orderId] && (
                                        <Typography 
                                            variant="caption" 
                                            color={cancelStatus[e._orderId].includes('successfully') ? 'success.main' : 'error.main'}
                                            sx={{ mt: 1, display: 'block', textAlign: 'center' }}
                                        >
                                            {cancelStatus[e._orderId]}
                                        </Typography>
                                    )}
                                    
                                 </Card>
                            </Grid>
                    ) })
                }
              </Grid>
                                        


          </Grid>
          
          {/* Add the ModalCreateOrder components here */}
          {filteredOrders.map(e => (
    <ModalCreateOrder
        key={`modal-${e._orderId}-${userAccount}`}
        activeStrike={String(e.strike)}
        activeExpiry={e.expiry}
        underlyingContract={e.underlyingContract}
        activePC={e.isCall ? 'call' : 'put'}
        bb={curr}
        switchPC={e.isCall ? 'Call' : 'Put'}
        isSpecific={!e.isGeneral} // Changed this line to negate e.isGeneral
        activeUnderlyingID={e.underlyingId}
        optionID={e.optionId}
        orderType={e.isOffer ? 'Bid' : 'Offer'}
        openT={openModalId === e._orderId}
        setOpenT={handleClose}
        expIsBlock={true}
        blockMapping={blockMapping}
        userAccount={userAccount}
    />
))}
      </CardContent>
</Card>)};














